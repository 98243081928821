import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/exteriors",
    name: "Exteriors list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "exteriors" */ "../views/exteriors/List.vue"),
  },
  {
    path: "/exteriors/form/:exteriorID?",
    name: "Create or edit exterior",
    component: () =>
      import(/* webpackChunkName: "exteriors" */ "../views/exteriors/Form.vue"),
  },
  {
    path: "/exteriors/assign",
    name: "Assign exteriors",
    component: () =>
      import(
        /* webpackChunkName: "exteriors" */ "../views/exteriors/Assign.vue"
      ),
  },
  {
    path: "/exteriors/assign/:enginelinkID",
    name: "Assign exteriors form",
    component: () =>
      import(
        /* webpackChunkName: "exteriors" */ "../views/exteriors/AssignForm.vue"
      ),
  },
];

export default routes;
