import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/modeltypes",
    name: "Modeltypes list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "modeltypes" */ "../views/modeltypes/List.vue"
      ),
  },
  {
    path: "/modeltypes/form/:modeltypeID?",
    name: "Create or edit modeltype / modelvariant",
    component: () =>
      import(
        /* webpackChunkName: "modeltypes" */ "../views/modeltypes/Form.vue"
      ),
  },
];

export default routes;
