import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/accessories",
    name: "accessories list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "accessories" */ "../views/accessories/List.vue"
      ),
  },
  {
    path: "/accessories/form/:accessoryID?",
    name: "Create or edit accessories",
    component: () =>
      import(
        /* webpackChunkName: "accessories" */ "../views/accessories/Form.vue"
      ),
  },
  {
    path: "/accessories/assign",
    name: "Assign accessories",
    component: () =>
      import(
        /* webpackChunkName: "accessories" */ "../views/accessories/Assign.vue"
      ),
  },
  {
    path: "/accessories/assign/:enginelinkID",
    name: "Assign accessories form",
    component: () =>
      import(
        /* webpackChunkName: "accessories" */ "../views/accessories/AssignForm.vue"
      ),
  },
];

export default routes;
