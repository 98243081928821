import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/categories",
    name: "Categories list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/categories/List.vue"),
  },
  {
    path: "/categories/form/:categoryID?",
    name: "Create or edit categories",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/categories/Form.vue"),
  },
];

export default routes;
