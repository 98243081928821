import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
// import VueLogger from "vuejs-logger";
import Keycloak from "keycloak-js";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink, from } from "apollo-link";
import "./global.css";

Vue.config.productionTip = false;

const initOptions = {
  url: process.env.VUE_APP_AUTH_URL,
  realm: "pdb",
  clientId: "pdb-client",
  onLoad: "login-required" as Keycloak.KeycloakOnLoad,
};

const keycloak = Keycloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth: boolean) => {
    if (!auth) {
      window.location.reload();
    } else {
      const httpLink = createHttpLink({
        uri: process.env.VUE_APP_GQL_URL,
      });
      const token = keycloak.idToken;

      const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        return forward(operation);
      });

      const errorLink = onError(
        ({ graphQLErrors, networkError, operation, forward }) => {
          if (graphQLErrors) {
            for (const err of graphQLErrors) {
              console.log(err);

              if (err.extensions) {
                console.log(err.extensions);
                switch (err.extensions.code) {
                  case "invalid-jwt":
                    console.log("handle unauth by setting new token");

                    operation.setContext({
                      headers: {
                        authorization: `Bearer ${keycloak.idToken}`,
                      },
                    });
                    return forward(operation);
                }
              }
            }
          }
        }
      );

      const apolloClient = new ApolloClient({
        link: from([authMiddleware, errorLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
          },
          query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
          },
          mutate: {
            errorPolicy: "all",
          },
        },
      });
      Vue.use(VueApollo);

      const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
      });
      new Vue({
        router,
        store,
        apolloProvider,
        render: (h) => h(App, { props: { keycloak: keycloak } }),
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token refreshed");
            console.log(keycloak.idToken);
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 6000);
  })
  .catch(() => {
    console.error("Authenticated Failed");
  });
