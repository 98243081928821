import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/engines",
    name: "Engines list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "engines" */ "../views/engines/List.vue"),
  },
  {
    path: "/engines/form/:engineID?",
    name: "Create or edit engines",
    component: () =>
      import(/* webpackChunkName: "engines" */ "../views/engines/Form.vue"),
  },
];

export default routes;
