






import Vue from "vue";
export default Vue.extend({
  props: {
    label: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default() {
        return "/";
      },
    },
  },
});
