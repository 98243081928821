import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/rims",
    name: "rims list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "rims" */ "../views/rims/List.vue"),
  },
  {
    path: "/rims/form/:rimID?",
    name: "Create or edit rim",
    component: () =>
      import(/* webpackChunkName: "rims" */ "../views/rims/Form.vue"),
  },
  {
    path: "/rims/assign",
    name: "Assign rims",
    component: () =>
      import(/* webpackChunkName: "rims" */ "../views/rims/Assign.vue"),
  },
  {
    path: "/rims/assign/:enginelinkID",
    name: "Assign rims form",
    component: () =>
      import(/* webpackChunkName: "rims" */ "../views/rims/AssignForm.vue"),
  },
];

export default routes;
