




















import Vue from "vue";
import gql from "graphql-tag";

export default Vue.extend({
  data() {
    return { selectedModel: 0 };
  },
  apollo: {
    pdb_modells: {
      query: gql`
        query pdb_modells {
          pdb_modells(
            order_by: { order: asc }
            where: {
              modeltypes: {
                trimlevels: { enginelinks: { id: { _is_null: false } } }
              }
            }
          ) {
            id
            name
            created
          }
        }
      `,
    },
  },
  watch: {
    pdb_modells(newVal: Array<any>) {
      if (newVal && newVal.length > 0 && this.selectedModel === 0) {
        this.modelSelected(newVal[0].id);
      }
    },
  },
  methods: {
    modelSelected(modelID: number) {
      this.selectedModel = modelID;
      this.$emit("setModelID", modelID);
    },
  },
});
