import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/trimlevels",
    name: "Trim level / spec level list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "trimlevels" */ "../views/trimlevels/List.vue"
      ),
  },
  {
    path: "/trimlevels/form/:trimlevelID?",
    name: "Create or edit trimlevels / spec level",
    component: () =>
      import(
        /* webpackChunkName: "trimlevels" */ "../views/trimlevels/Form.vue"
      ),
  },
];

export default routes;
