
















import Vue from "vue";
export default Vue.extend({
  props: ["keycloak"],
  methods: {
    async logout() {
      const res = await this.keycloak.logout();
      console.log(res);
    },
  },
});
