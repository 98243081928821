



















import Vue from "vue";
import MainNav from "@/components/navigation/MainNav.vue";
import LogoutButton from "@/components/LogoutButton.vue";

export default Vue.extend({
  props: {
    keycloak: {
      type: Object,
      required: true,
    },
  },
  components: {
    MainNav,
    LogoutButton,
  },
  data() {
    return {
      user: {} as Object,
    };
  },
  async created() {
    const user = await this.keycloak.loadUserInfo();
    this.user = user;
  },
});
