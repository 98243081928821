import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/drives",
    name: "Drives list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "drives" */ "../views/drives/List.vue"),
  },
  {
    path: "/drives/form/:driveID?",
    name: "Create or edit drives",
    component: () =>
      import(/* webpackChunkName: "drives" */ "../views/drives/Form.vue"),
  },
];

export default routes;
