



















































import Vue from "vue";
import gql from "graphql-tag";
import Headline from "@/components/Headline.vue";
import ModelTabs from "@/components/ModelTabs.vue";
import Head from "@/components/table/Head.vue";
import DefaultCell from "@/components/table/DefaultCell.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";

export default Vue.extend({
  apollo: {
    pdb_enginelinks: {
      query: gql`
        query pdb_enginelinks($modelID: bigint) {
          pdb_enginelinks(
            where: {
              trimlevel: { modeltype: { modell_id: { _eq: $modelID } } }
            }
          ) {
            enginetype {
              name
              engine {
                name
              }
              gear {
                name
              }
              drive {
                name
              }
            }
            id
            price
            trimlevel {
              name
              modeltype {
                name
              }
            }
          }
        }
      `,
      variables() {
        if (this.selectedModelID) {
          return {
            modelID: this.selectedModelID,
          };
        }
        return { modelID: null };
      },
    },
  },
  data() {
    return {
      selectedModelID: 0 as number,
    };
  },
  methods: {
    setModel(modelID: number): void {
      this.selectedModelID = modelID;
    },
    updatePrice(enginelinkID: number, price: string) {
      console.log("ID", enginelinkID);
      console.log("Preis", price);

      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($id: bigint!, $price: numeric) {
              update_pdb_enginelinks_by_pk(
                pk_columns: { id: $id }
                _set: { price: $price }
              ) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            id: enginelinkID,
            price: price,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_enginelinks.refetch();
          //   this.pdb_wheelslink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
  },
  components: {
    Headline,
    ModelTabs,
    Head,
    DefaultCell,
    NumberInput,
    PrimaryButton,
  },
});
