import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import ModelsRoutes from "./models";
import ModeltypesRoutes from "./modeltypes";
import TrimlevelsRoutes from "./trimlevels";
import GearsRoutes from "./gears";
import DrivesRoutes from "./drives";
import EnginesRoutes from "./engines";
import DrivetrainsRoutes from "./drivetrains";
import CategoriesRoutes from "./categories";
import EquipentFeaturesRoutes from "./equipment-features";
import ExteriorsRoutes from "./exteriors";
import InteriorsRoutes from "./interiors";
import RimsRoutes from "./rims";
import AccessoriesRoutes from "./accessories";
import BasePrice from "../views/BasePrice.vue";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/base-price",
    name: "Basis Preis",
    component: BasePrice,
  },
];

routes = routes.concat(
  ModelsRoutes,
  ModeltypesRoutes,
  TrimlevelsRoutes,
  GearsRoutes,
  DrivesRoutes,
  EnginesRoutes,
  DrivetrainsRoutes,
  CategoriesRoutes,
  EquipentFeaturesRoutes,
  ExteriorsRoutes,
  InteriorsRoutes,
  RimsRoutes,
  AccessoriesRoutes
);

const router = new VueRouter({
  routes,
});

export default router;
