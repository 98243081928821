import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/models",
    name: "Models list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "models" */ "../views/models/List.vue"),
  },
  {
    path: "/models/form",
    name: "create_model",
    component: () =>
      import(/* webpackChunkName: "models" */ "../views/models/Form.vue"),
  },
  {
    path: "/models/form/:modelID",
    name: "edit_model",
    component: () =>
      import(/* webpackChunkName: "models" */ "../views/models/Form.vue"),
  },
];

export default routes;
