import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/interiors",
    name: "Interiors list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "interiors" */ "../views/interiors/List.vue"),
  },
  {
    path: "/interiors/form/:interiorID?",
    name: "Create or edit interior",
    component: () =>
      import(/* webpackChunkName: "interiors" */ "../views/interiors/Form.vue"),
  },
  {
    path: "/interiors/assign",
    name: "Assign interiors",
    component: () =>
      import(
        /* webpackChunkName: "interiors" */ "../views/interiors/Assign.vue"
      ),
  },
  {
    path: "/interiors/assign/:enginelinkID",
    name: "Assign interiors form",
    component: () =>
      import(
        /* webpackChunkName: "interiors" */ "../views/interiors/AssignForm.vue"
      ),
  },
];

export default routes;
