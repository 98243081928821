import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/drivetrains",
    name: "Drivetrain list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "drivetrain" */ "../views/drivetrains/List.vue"
      ),
  },
  {
    path: "/drivetrains/form/:drivetrainID?",
    name: "Create or edit drivetrains",
    component: () =>
      import(
        /* webpackChunkName: "drivetrain" */ "../views/drivetrains/Form.vue"
      ),
  },
  {
    path: "/drivetrains/assign/trimlevels",
    name: "List assigned trimlevels of drivetrain",
    component: () =>
      import(
        /* webpackChunkName: "drivetrain" */ "../views/drivetrains/AssignTrimlevelList.vue"
      ),
  },
  {
    path: "/drivetrains/assign/trimlevels/:enginetypeID",
    name: "Assign trimlevels of drivetrain",
    component: () =>
      import(
        /* webpackChunkName: "drivetrain" */ "../views/drivetrains/AssignForm.vue"
      ),
  },
];

export default routes;
