











import Vue from "vue";
import LogoutButton from "@/components/LogoutButton.vue";
import Header from "./components/Header.vue";

export default Vue.extend({
  props: {
    keycloak: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: {},
    };
  },
  components: {
    LogoutButton,
    Header,
  },
});
