import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/equipment-features",
    name: "Equipment list",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "../views/equipment-features/List.vue"
      ),
  },
  {
    path: "/equipment-features/form/:equipmentID?",
    name: "Create or edit equipment",
    component: () =>
      import(
        /* webpackChunkName: "equipment" */ "../views/equipment-features/Form.vue"
      ),
  },
  {
    path: "/equipment-features/assign",
    name: "Assign equipment features",
    component: () =>
      import(
        /* webpackChunkName: "drivetrain" */ "../views/equipment-features/Assign.vue"
      ),
  },
  {
    path: "/equipment-features/assign/:enginelinkID",
    name: "Assign equipment features to enginelink form",
    component: () =>
      import(
        /* webpackChunkName: "drivetrain" */ "../views/equipment-features/AssignForm.vue"
      ),
  },
];

export default routes;
