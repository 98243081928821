


























































































import Vue from "vue";
import TopNavItem from "./TopNavItem.vue";
import SubNavGroup from "./SubNavGroup.vue";
import SubNavItem from "./SubNavItem.vue";

export default Vue.extend({
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    showMenu() {
      this.isVisible = true;
    },
    hideMenu() {
      this.isVisible = false;
    },
  },
  components: {
    TopNavItem,
    SubNavGroup,
    SubNavItem,
  },
});
